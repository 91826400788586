.Project {
  height: 400px;
  width: 300px;
  padding: 10px;
  box-shadow: -10px 0 10px #ccc;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-width: 250px;
  transition: 0.2s;
}

.Project:hover {
  transform: translateY(-2rem);
  box-shadow: 5px 5px 30px rgb(49, 54, 136);
}

.Project:hover ~ .Project {
  transform: translateX(50px);
}

.Project:not(:first-child) {
  margin-left: -10px;
}

.Project img {
  border-color: rgb(209, 71, 101);
  border-width: 5px;
  width: 100%;
  height: 150px;
}

@media only screen and (max-width: 600px) {
  .Project {
    box-shadow: 0 -10px 10px #ccc;
    height: 500px;
  }
  .Project:not(:first-child) {
    margin-left: 0px;
  }
  .Project:hover ~ .Project {
    transform: translateY(20px);
  }
}
