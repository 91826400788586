.SkillsList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .SkillsList {
    flex-direction: column;
  }
}
