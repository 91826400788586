.Skill {
  display: flex;
  margin-left: 1.5%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 8%;
  width: 6.5%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}
.Skill img {
  width: 80px;
  height: 80px;
}
.Skill:hover {
  box-shadow: 0 0 20px rgb(115, 117, 219);
  width: 90px;
}
@media only screen and (max-width: 600px) {
  .Skill {
    width: 70%;
    margin-bottom: 20px;
  }
  .Skill:hover {
    width: 75%;
  }
}
