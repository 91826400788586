.Projects {
  height: 420px;
  padding: 30px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  flex-direction: row;
  overflow: scroll;
  /* justify-content: baseline; */
}
.Projects::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .Projects {
    flex-direction: column;
  }
}

.ProjectScore {
  height: 95px;
  padding: 10px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  flex-direction: row;
  overflow: scroll;
  /* justify-content: baseline; */
}

@media only screen and (max-width: 600px) {
  .ProjectScore {
    flex-direction: column;
  }
}
