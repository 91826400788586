.ContactView {
  padding: 20px;
  /* height: 400px; */
  min-height: 300px;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  overflow: auto;
  align-items: center;
  text-align: center;
}

.ContactView input {
  margin: 10px 20px;
  padding: 8px 12px;
  box-shadow: 0 0 6px rgb(145, 139, 139);
  background-color: rgb(39, 38, 38);
  font-size: 20px;
  border: 2px solid rgb(131, 125, 125);
  width: 50%;
  color: whitesmoke;
  border-radius: 8px;
}
.ContactView textarea {
  margin: 10px 20px;
  padding: 8px 12px;
  font-size: 20px;
  box-shadow: 0 0 6px #ccc;
  background-color: rgb(39, 38, 38);
  border: 2px solid #ccc;
  border-radius: 8px;
  color: whitesmoke;

  width: -webkit-fill-available;
}

.ContactView button {
  height: 40px;
  border-radius: 10px;
  border: 1px;
  background-color: rgb(38, 38, 245);
  box-shadow: 0 0 10px rgb(187, 184, 184);
  border: 2px solid rgb(190, 183, 183);
  width: 100px;
}
