* {
  font-family: "Rancho", cursive;
  /* background-color: rgb(153, 133, 133); */
}

.App {
  padding: 10%;
  padding-top: 10px;
  /* color: #fff; */
  /* background-color: #121214; */
}
@media only screen and (max-width: 600px) {
  .App {
    padding: 20px;
  }
}
