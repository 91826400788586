.Profile {
  padding: 20px;
  display: flex;
  /* height: 400px; */
  min-height: 400px;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  background: -webkit-linear-gradient(120deg, rgb(28, 42, 243) 50%, rgba(78, 77, 77, 0) 33%);
  overflow: auto;

  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.Profile img {
  height: 200px;
  width: 200px;
  border-radius: 80%;
}

.Profile::-webkit-scrollbar {
  display: none;
}

.ProfileDescription {
  width: 60%;
}

@media only screen and (max-width: 600px) {
  .Profile {
    flex-direction: column;
  }

  .ProfileDescription {
    width: 100%;
  }
}
